<template>
	<div class="qr-table">
		<div class="d-flex tabs mb-3">
			<v-btn
				v-for="link of links"
				:key="link.to"
				:to="link.to"
				color="white primary--text"
				class="text-capitalize subtitle-2 mr-5"
				link
				depressed
			>
				{{ link.title }}
			</v-btn>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "QrNextTable",

	data() {
		return {
			links: [
				{ to: "/qr-next/groups", title: "Группы" },
				{ to: "/qr-next/logs", title: "Логи" },
				{ to: "/qr-next/devices", title: "Устройства" },
				// { to: "/qr-next/whitelist", title: "Белые листы" }
			],
		};
	},
	async mounted() {
		this.$store.dispatch("catalog/loadQrStatuses");
		this.$store.dispatch("catalog/loadQrErrors");
	},
};
</script>

<style lang="scss" scoped></style>
